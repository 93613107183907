<template>
  <b-sidebar
    id="edit-driver-sidebar"
    :visible="isEditDriverSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-driver-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Driver
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(updateJobDriver)"
        >

          <!-- User Role -->
          <b-form-group
            label="Name"
            label-for="vehicle-data"
          >
            <v-select
              v-model="newDriverData"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="driverOptions"
              :reduce="val => val"
              :clearable="false"
              label="fullName"
              input-id="driver-data"
            >
              <template #option="{ fullName, statusText }">
                <div class="d-flex justify-content-between align-items-center">
                  {{ fullName }}
                  <b-badge
                    :variant="`light-${resolveUserStatusVariant(statusText.toLowerCase())}`"
                  >
                    {{ statusText }}
                  </b-badge>
                </div>
              </template>
            </v-select>
          </b-form-group>

          <div v-if="!!newDriverData">
            <b-card
              no-body
              :class="`border-${resolveUserStatusVariant(newDriverData.statusText.toLowerCase())}`"
            >
              <b-card-header class="d-flex justify-content-between pt-75 pb-25 px-1">
                <h3 class="mb-0">
                  {{ newDriverData.fullName }}
                </h3>
                <b-badge
                  :variant="`light-${resolveUserStatusVariant(newDriverData.statusText.toLowerCase())}`"
                >
                  {{ newDriverData.statusText }}
                </b-badge>
                <small
                  class="text-muted w-100"
                >{{ newDriverData.roleText }}</small>
              </b-card-header>
              <b-card-body class="px-1">
                <ul
                  class="list-unstyled my-1"
                >
                  <li>
                    <span class="align-middle">Phone Number: {{ newDriverData.phoneNumber ? newDriverData.phoneNumber : 'N/A' }}</span>
                  </li>
                  <li>
                    <span class="align-middle">Email Address: {{ newDriverData.email ? newDriverData.email : 'N/A' }}</span>
                  </li>
                </ul>
              </b-card-body>
            </b-card>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                class="mr-50"
              />
              {{ isLoading ? "Updating..." : "Update" }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="flat-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BBadge, BFormGroup, BCard, BCardHeader, BCardBody, BFormInvalidFeedback, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUsersList from '@/views/user/users-list/useUsersList'

export default {
  components: {
    BSidebar,
    BForm,
    BCard,
    BCardBody,
    BCardHeader,
    BBadge,
    BSpinner,
    BFormGroup,
    BButton,
    vSelect,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditDriverSidebarActive',
    event: 'update:is-edit-driver-sidebar-active',
  },
  props: {
    isEditDriverSidebarActive: {
      type: Boolean,
      required: true,
    },
    driverData: {
      type: Object,
      required: false,
      default: null,
    },
    jobId: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      required,
      newDriverData: this.driverData,
      isLoading: false,
    }
  },
  methods: {
    updateJobDriver() {
      this.isLoading = true
      const updateData = {
        jobId: this.jobId,
        driverId: this.newDriverData.id,
      }

      store.dispatch('app-user/updateJobDriver', updateData)
        .then(response => {
          this.$emit('updateJobCanSchedule', response.data.canSchedule)
          this.$emit('updateDriver', this.newDriverData)
          this.$emit('update:is-edit-driver-sidebar-active', false)

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully changed the driver for this job.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$emit('update:is-edit-driver-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating the job.',
            },
          })
          this.isLoading = false
        })
    },
  },
  setup(props, { emit }) {
    const driverOptions = ref([])

    store.dispatch('app-user/fetchDrivers')
      .then(response => {
        driverOptions.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          driverOptions.value = undefined
        }
      })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const { resolveUserStatusVariant } = useUsersList()
    return {
      driverOptions,
      refFormObserver,
      getValidationState,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-vehicle-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
