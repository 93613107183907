import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useJobsList() {
  // Use toast
  const toast = useToast()

  const refJobListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'alias', label: 'Alias', sortable: true,
    },
    { key: 'scheduled', label: 'Scheduled', sortable: true },
    { key: 'progressPercentage', label: 'Progress' },
    { key: 'driver', label: 'Driver' },
    { key: 'customer', label: 'Customer' },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'actions' },
  ]

  const statusFilters = JSON.parse(localStorage.getItem('statusFilters'))

  if (statusFilters === null) {
    localStorage.setItem('statusFilters', JSON.stringify({
      draft: true,
      scheduled: true,
      inProgress: true,
      completed: false,
    }))
  }

  const perPage = ref(10)
  const totalJobs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const statusDraft = ref(statusFilters.draft)
  const statusScheduled = ref(statusFilters.scheduled)
  const statusInProgress = ref(statusFilters.inProgress)
  const statusCompleted = ref(statusFilters.completed)
  const sortBy = ref('scheduled')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refJobListTable.value ? refJobListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalJobs.value,
    }
  })

  const refetchData = () => {
    refJobListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusDraft, statusScheduled, statusInProgress, statusCompleted], () => {
    refetchData()
  })

  const fetchJobs = (ctx, callback) => {
    localStorage.setItem('statusFilters', JSON.stringify({
      draft: statusDraft.value,
      scheduled: statusScheduled.value,
      inProgress: statusInProgress.value,
      completed: statusCompleted.value,
    }))

    store
      .dispatch('app-job/fetchJobs', {
        limit: perPage.value,
        page: currentPage.value,
        sort: `j.${sortBy.value}`,
        direction: isSortDirDesc.value ? 'desc' : 'asc',
        q: searchQuery.value,
        statusDraft: statusDraft.value,
        statusScheduled: statusScheduled.value,
        statusInProgress: statusInProgress.value,
        statusCompleted: statusCompleted.value,
      })
      .then(response => {
        const { jobs, meta } = response.data

        callback(jobs)
        totalJobs.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching jobs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveJobStatusVariant = status => {
    if (status === 'status.draft') return 'primary'
    if (status === 'status.scheduled') return 'info'
    if (status === 'status.inProgress') return 'warning'
    if (status === 'status.completed') return 'success'
    return 'secondary'
  }

  const resolveJobStatusText = status => {
    if (status === 'status.draft') return 'Draft'
    if (status === 'status.scheduled') return 'Scheduled'
    if (status === 'status.inProgress') return 'In Progress'
    if (status === 'status.completed') return 'Completed'
    return 'N/A'
  }

  const resolveJobStatusIcon = status => {
    if (status === 'status.draft') return 'CircleIcon'
    if (status === 'status.scheduled') return 'ClockIcon'
    if (status === 'status.inProgress') return 'ActivityIcon'
    if (status === 'status.completed') return 'CheckIcon'
    return 'N/A'
  }

  return {
    fetchJobs,
    tableColumns,
    perPage,
    currentPage,
    totalJobs,
    dataMeta,
    perPageOptions,
    searchQuery,
    statusDraft,
    statusScheduled,
    statusInProgress,
    statusCompleted,
    sortBy,
    isSortDirDesc,
    refJobListTable,

    resolveJobStatusVariant,
    resolveJobStatusText,
    resolveJobStatusIcon,
    refetchData,
  }
}
