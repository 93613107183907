var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":{
        required: true
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","value":_vm.name,"placeholder":"Name"},on:{"input":function($event){return _vm.$emit('update:name', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vue-autosuggest',{ref:"addressRef",attrs:{"value":_vm.address,"placeholder":"Address","suggestions":_vm.suggestions,"input-props":_vm.inputProps,"render-suggestion":_vm.renderSuggestion,"section-configs":_vm.sectionConfigs,"get-suggestion-value":_vm.getSuggestionValue},on:{"input":function($event){_vm.fetchResults($event); _vm.$emit('update:address', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phone-number"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":{
        phoneNumber: true
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone-number","value":_vm.phone,"placeholder":"Phone Number"},on:{"input":function($event){return _vm.$emit('update:phone', $event)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }