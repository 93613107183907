<template>
  <div>
    <!-- Name -->
    <b-form-group
      label="Name"
      label-for="name"
    >
      <validation-provider
        #default="{ errors }"
        name="name"
        :rules="{
          required: true
        }"
      >
        <b-form-input
          id="name"
          :value="name"
          placeholder="Name"
          @input="$emit('update:name', $event)"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- Address -->
    <b-form-group
      label="Address"
      label-for="address"
    >
      <validation-provider
        #default="{ errors }"
        name="Address"
        rules="required"
      >
        <vue-autosuggest
          ref="addressRef"
          :value="address"
          placeholder="Address"
          :suggestions="suggestions"
          :input-props="inputProps"
          :render-suggestion="renderSuggestion"
          :section-configs="sectionConfigs"
          :get-suggestion-value="getSuggestionValue"
          @input="fetchResults($event); $emit('update:address', $event)"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!-- Phone Number -->
    <b-form-group
      label="Phone Number"
      label-for="phone-number"
    >
      <validation-provider
        #default="{ errors }"
        name="Phone Number"
        :rules="{
          phoneNumber: true
        }"
      >
        <b-form-input
          id="phone-number"
          :value="phone"
          placeholder="Phone Number"
          @input="$emit('update:phone', $event)"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import phoneNumber from '@/libs/utils/validations/customValidations'
import Ripple from 'vue-ripple-directive'
import { VueAutosuggest } from 'vue-autosuggest'
import { Loader } from '@googlemaps/js-api-loader'

export default {
  emits: [
    'update:address',
    'update:phone',
    'update:name',
    'update:is-edit-pick-up-sidebar-active',
  ],
  inject: ['$validator'],
  components: {
    BFormInput,
    BFormGroup,
    VueAutosuggest,

    // Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    address: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const addressRef = ref()
    const isLoading = ref(false)
    const sessionToken = ref()
    const timeout = ref(null)
    const debounceMilliseconds = ref(450)
    const suggestions = ref([])
    const selected = ref()
    const inputProps = {
      id: 'autosuggest__input',
      placeholder: 'Enter Address',
      class: 'form-control',
      name: 'google',
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const loadGoogleMapsApi = async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyBrkNlraUI2o9Mo72xTR8WqWDeQkwgArVQ',
      })

      return loader.importLibrary('places')
    }

    const createSessionToken = () => {
      sessionToken.value = new google.maps.places.AutocompleteSessionToken()
    }

    const getPlaceDetails = async placeId => {
      const placesService = new google.maps.places.PlacesService(document.createElement('div'))
      placesService.getDetails(
        {
          placeId,
          sessionToken: sessionToken.value,
          fields: ['formatted_address', 'geometry', 'address_components'],
        }, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            selected.value = place
            context.emit('update:address', place.formatted_address)
          }
        },
      )
    }

    const sectionConfigs = {
      current: {
        limit: 1,
        onSelected: current => {
          selected.value = current.item
        },
      },
      google: {
        limit: 5,
        onSelected: current => {
          selected.value = current.item
          getPlaceDetails(current.item.place_id)
        },
      },
    }

    const getSuggestionValue = suggestion => suggestion.item.description

    const fetchResults = event => {
      clearTimeout(timeout)
      timeout.value = setTimeout(async () => {
        const autocompleteService = new google.maps.places.AutocompleteService()
        try {
          const results = await new Promise((resolve, reject) => {
            autocompleteService.getPlacePredictions(
              {
                input: event,
                region: 'GB',
                componentRestrictions: {
                  country: ['GB', 'IE'],
                },
                sessionToken: sessionToken.value,
              },
              (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  resolve(predictions)
                } else {
                  reject(status)
                }
              },
            )
          })

          suggestions.value = [{
            name: 'google',
            data: results,
          }]
        } catch (e) {
          suggestions.value = []
          suggestions.value = [{
            name: 'google',
            data: [],
          }]
        }

        if (props.address.length > 0) {
          suggestions.value.push({
            name: 'current',
            data: [
              {
                description: props.address,
              },
            ],
          })
        }
      }, debounceMilliseconds)
    }

    const renderSuggestion = suggestion => suggestion.item.description

    onMounted(() => {
      loadGoogleMapsApi().then(() => {
        createSessionToken()
      })
    })

    return {
      suggestions,
      addressRef,

      inputProps,
      renderSuggestion,
      getSuggestionValue,
      fetchResults,
      sectionConfigs,

      isLoading,
      required,
      phoneNumber,
      refFormObserver,
      getValidationState,
    }
  },

}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
