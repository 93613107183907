<template>
  <div>
    <job-edit-pick-up
      :is-edit-pick-up-sidebar-active.sync="isEditPickUpSidebarActive"
      :pick-up-data="viewPickUpData"
      :job-id="jobId"
      @updatePickUp="jobViewPickUpUpdate"
      @updateJobCanSchedule="handleUpdateJobCanSchedule"
    />
    <b-card
      no-body
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Pick Up
        </h5>
      </b-card-header>

      <b-card-body v-if="!viewPickUpData">
        No pick up checkpoint added.
        <b-row class="px-1 pt-2 d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            @click="isEditPickUpSidebarActive = true"
          >
            <span class="text-nowrap">Add Pick Up</span>
          </b-button>
        </b-row>
      </b-card-body>
      <b-card-body v-else>
        <ul
          v-if="viewPickUpData"
          class="list-unstyled my-1"
        >
          <li>
            <span class="align-middle">Name: {{ viewPickUpData.name ? viewPickUpData.name : 'Not set' }}</span>
          </li>
          <li>
            <span class="align-middle">Address: {{ viewPickUpData.address }}</span>
          </li>
          <li class="my-25">
            <span class="align-middle">Phone number: {{ viewPickUpData.phoneNumber ? viewPickUpData.phoneNumber : 'N/A' }}</span>
          </li>
        </ul>
        <b-row
          align-h="end"
          class="mx-auto justify-content-between"
        >
          <b-button
            v-if="isEditable"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="isEditPickUpSidebarActive = true"
          >
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-button>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import JobEditPickUp from '@/views/job/jobs-edit/JobEditPickUp.vue'
import checkpointStoreModule from '@/views/checkpoint/checkpointStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    JobEditPickUp,
  },
  props: {
    pickUpData: {
      type: Object,
      required: false,
      default: null,
    },
    jobId: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      viewPickUpData: this.$props.pickUpData,
    }
  },
  methods: {
    jobViewPickUpUpdate(data) {
      this.viewPickUpData = data
    },
    handleUpdateJobCanSchedule(data) {
      this.$emit('updateJobCanSchedule', data)
    },
  },
  setup() {
    const CHECKPOINT_APP_STORE_MODULE_NAME = 'app-checkpoint'
    // Register module
    if (!store.hasModule(CHECKPOINT_APP_STORE_MODULE_NAME)) store.registerModule(CHECKPOINT_APP_STORE_MODULE_NAME, checkpointStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHECKPOINT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHECKPOINT_APP_STORE_MODULE_NAME)
    })

    const isEditPickUpSidebarActive = ref(false)
    return {
      isEditPickUpSidebarActive,
    }
  },
}
</script>

<style>

</style>
