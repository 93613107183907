<template>
  <div>
    <job-edit-driver
      :is-edit-driver-sidebar-active.sync="isEditDriverSidebarActive"
      :driver-data="viewDriverData"
      :job-id="jobId"
      @updateDriver="jobViewDriverUpdate"
      @updateJobCanSchedule="handleUpdateJobCanSchedule"
    />

    <b-card
      no-body
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Driver
        </h5>
        <b-badge
          v-if="!!viewDriverData"
          :variant="`light-${resolveUserStatusVariant(viewDriverData.statusText.toLowerCase())}`"
        >
          {{ viewDriverData.statusText }}
        </b-badge>
        <small
          v-if="viewDriverData"
          class="text-muted w-100"
        >{{ viewDriverData.email }}</small>
      </b-card-header>

      <b-card-body v-if="!viewDriverData">
        Driver not added.
        <b-row class="px-1 pt-2 d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            @click="isEditDriverSidebarActive = true"
          >
            <span class="text-nowrap">Add Driver</span>
          </b-button>
        </b-row>
      </b-card-body>
      <b-card-body v-else>
        <ul
          v-if="viewDriverData"
          class="list-unstyled my-1"
        >
          <li>
            <span class="align-middle">{{ viewDriverData.fullName }}</span>
          </li>
          <li>
            <span class="align-middle">Phone Number: {{ viewDriverData.phoneNumber ? viewDriverData.phoneNumber : 'N/A' }}</span>
          </li>
        </ul>
        <b-row
          align-h="end"
          class="mx-auto justify-content-between"
        >
          <b-button
            v-if="!!viewDriverData.id"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :to="{name: 'users-view', params: {id: viewDriverData.id }}"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            <span>View</span>
          </b-button>
          <b-button
            v-if="isEditable"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="isEditDriverSidebarActive = true"
          >
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-button>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useUsersList from '@/views/user/users-list/useUsersList'
import JobEditDriver from '@/views/job/jobs-edit/JobEditDriver.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import userStoreModue from '@/views/user/userStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BRow,
    JobEditDriver,
  },
  props: {
    driverData: {
      type: Object,
      required: false,
      default: null,
    },
    jobId: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      viewDriverData: this.$props.driverData,
    }
  },
  methods: {
    jobViewDriverUpdate(data) {
      this.viewDriverData = data
    },
    handleUpdateJobCanSchedule(data) {
      this.$emit('updateJobCanSchedule', data)
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModue)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const { resolveUserStatusVariant } = useUsersList()
    const isEditDriverSidebarActive = ref(false)
    return {
      resolveUserStatusVariant,
      isEditDriverSidebarActive,
    }
  },
}
</script>

<style>

</style>
