<template>

  <div>
    <div class="pb-50">
      <span class="mr-50 font-weight-bold">Mileage:</span>
      <span>{{ vehicleCheckData.mileage }}</span>
    </div>
    <div class="pb-50">
      <span class="mr-50 font-weight-bold">Performed:</span>
      <span>{{ toHumanDate(vehicleCheckData.updatedAt) }}</span>
    </div>
    <div v-if="vehicleCheckData.checkType === 'type.full'">
      <span class="mr-50 font-weight-bold">Check:</span>
      <span :class="`font-weight-bold text-${vehicleCheckData.outcome ? 'success' : 'danger'}`">{{ vehicleCheckData.outcome ? 'Successful' : 'Failed' }}</span>
    </div>
  </div>
</template>

<script>
import toHumanDate from '@/libs/utils/general/dates'

export default {
  props: {
    vehicleCheckData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toHumanDate(date) {
      return toHumanDate(date)
    },
  },
}
</script>
