<template>
  <b-card title="Job Progress">
    <template
      v-for="(iteration, iterationIndex) in jobData.iteration + 1"
    >
      <div :key="iteration">

        <div class="divider my-2">
          <div class="divider-text">
            Attempt {{ iteration }}
          </div>
        </div>

        <app-timeline>
          <template
            v-for="(timelineItem, index) in timelineItems(iterationIndex)"
          >
            <app-timeline-item
              v-if="timelineItem !== null && timelineItem !== undefined"
              :key="index"
              :icon="resolveTimelineTypeIcon(timelineItem.type)"
              :variant="resolveTimelineStatusVariant(timelineItem.progressItemData ? timelineItem.progressItemData.status : null)"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ resolveTimelineTypeText(timelineItem.type) }}</h6>
                <small
                  v-if="timelineItem.progressItemData !== null && timelineItem.progressItemData.status === 'status.completed'"
                  class="text-muted"
                >Updated {{ timelineItem.progressItemData ? (moment(timelineItem.progressItemData.updatedAt)) : null }}</small>
              </div>
              <p>{{ timelineItem.description }}</p>
              <timeline-progress-extra-data
                v-if="timelineItem !== undefined && timelineItem.progressItemData !== null"
                :progress-item="timelineItem.progressItemData"
              />
            </app-timeline-item>
          </template>
        </app-timeline>
      </div>
    </template>

  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import useJobProgressList from '@/views/job/jobs-view/useJobProgressList'
import moment from 'moment'
import TimelineProgressExtraData from '@/views/job/jobs-view/TimelineProgressExtraData.vue'
import { onMounted } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    TimelineProgressExtraData,
  },

  props: {
    jobData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    moment(date) {
      return moment(date).fromNow()
    },
  },
  setup(props) {
    const {
      resolveTimelineTypeText,
      resolveTimelineTypeIcon,
      resolveTimelineStatusText,
      resolveTimelineStatusVariant,
    } = useJobProgressList()

    const timelineItems = iteration => props.jobData.jobTemplate.jobItems.map(jobItem => {
      const progressItemData = props.jobData.jobProgressItems.find(
        progressItem => progressItem.jobItem.id === jobItem.id && progressItem.jobIteration === iteration,
      )

      if (iteration < props.jobData.iteration && !progressItemData) {
        return null
      }
      return {
        ...jobItem,
        progressItemData: progressItemData || null,
      }
    }, props)

    return {
      timelineItems,
      resolveTimelineTypeText,
      resolveTimelineTypeIcon,
      resolveTimelineStatusText,
      resolveTimelineStatusVariant,
    }
  },
}
</script>

<style>

</style>
