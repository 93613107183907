import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/customers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllCustomers(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/customers', { params: { showAll: true } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createNewCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/customers', customerData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, { customerId, customerData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/customers/${customerId}`, customerData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
