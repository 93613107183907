<template>
  <b-sidebar
    id="edit-drop-off-sidebar"
    :visible="isEditDropOffSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-drop-off-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Drop Off
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleUpdateDropOff)"
        >
          <address-field-group
            :name="form.dropOffAddress.name"
            :address="form.dropOffAddress.address"
            :phone="form.dropOffAddress.phoneNumber"
            @update:name="form.dropOffAddress.name = $event"
            @update:address="form.dropOffAddress.address = $event"
            @update:phone="form.dropOffAddress.phoneNumber = $event"
          />

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid"
            >
              <b-spinner
                v-if="isLoading"
                small
                class="mr-50"
              />
              {{ isLoading ? "Updating..." : "Update" }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="flat-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import store from '@/store'
import { required } from '@validations'
import phoneNumber from '@/libs/utils/validations/customValidations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddressFieldGroup from '@/components/AddressFieldGroup.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BSidebar,
    AddressFieldGroup,
    BForm,
    BButton,
    BSpinner,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditDropOffSidebarActive',
    event: 'update:is-edit-drop-off-sidebar-active',
  },
  props: {
    isEditDropOffSidebarActive: {
      type: Boolean,
      required: true,
    },
    dropOffData: {
      type: Object,
      required: false,
      default: null,
    },
    jobId: {
      type: String,
      default: '',
      required: true,
    },
  },
  provide() {
    return {
      $validator: this.$validator,
    }
  },
  data() {
    return {
      dropOffAddress: '',
      fullAddress: '',
      // Validation Rules
      required,
      phoneNumber,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const customerOptions = ref([])
    const isLoading = ref(false)

    const form = ref({
      dropOffAddress: {
        id: null,
        name: '',
        address: '',
        phoneNumber: null,
      },
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    if (props.dropOffData) {
      form.value.dropOffAddress = { ...form.value.dropOffAddress, ...props.dropOffData }
    }

    const addDropOff = () => {
      const checkpointData = {
        jobId: props.jobId,
        checkpointData: {
          type: 'type.drop-off',
          name: form.value.dropOffAddress.name,
          address: form.value.dropOffAddress.address,
          phoneNumber: form.value.dropOffAddress.phoneNumber,
        },
      }
      store.dispatch('app-job/addJobCheckpoint', checkpointData)
        .then(response => {
          form.value.dropOffAddress = response.data.checkpoints?.find(
            checkpoint => checkpoint.type === 'type.drop-off',
          )
          emit('updateJobCanSchedule', response.data.canSchedule)
          emit('updateDropOff', form.value.dropOffAddress)
          emit('update:is-edit-drop-off-sidebar-active', false)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated the pick up checkpoint for this job.',
            },
          })
          isLoading.value = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          emit('update:is-edit-drop-off-sidebar-active', false)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating the job.',
            },
          })
          isLoading.value = false
        })
    }

    const updateDropOff = () => {
      const checkpointData = {
        type: 'type.drop-off',
        name: form.value.dropOffAddress.name,
        address: form.value.dropOffAddress.address,
        phoneNumber: form.value.dropOffAddress.phoneNumber,
      }
      store.dispatch('app-checkpoint/updateJobCheckpoint', { checkpointData, checkpointId: form.value.dropOffAddress.id })
        .then(response => {
          emit('updateJobCanSchedule', response.canSchedule)
          emit('updateDropOff', form.value.dropOffAddress)
          emit('update:is-edit-drop-off-sidebar-active', false)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated the pick up checkpoint for this job.',
            },
          })
          isLoading.value = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          emit('update:is-edit-drop-off-sidebar-active', false)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating the job.',
            },
          })
          isLoading.value = false
        })
    }

    const handleUpdateDropOff = () => {
      isLoading.value = true
      if (form.value.dropOffAddress.id !== null) {
        return updateDropOff()
      }
      return addDropOff()
    }

    return {
      customerOptions,
      refFormObserver,
      getValidationState,
      form,
      handleUpdateDropOff,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-drop-off-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
