<template>
  <div>
    <div class="pb-50">
      <b-img
        class="col-xl-3 col-lg-4 col-md-8 col-sm-12 mt-1 mb-1"
        :src="signatureData.image"
        v-bind="mainProps"
      />
    </div>
    <div class="pb-50">
      <span class="mr-50 font-weight-bold">Recipient name:</span>
      <span>{{ signatureData.name }}</span>
    </div>
  </div>

</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    signatureData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mainProps: {

      },
    }
  },
}
</script>
