<template>
  <div>
    <job-edit-customer
      :is-edit-customer-sidebar-active.sync="isEditCustomerSidebarActive"
      :driver-data="viewCustomerData"
      :job-id="jobId"
      @updateCustomer="jobViewCustomerUpdate"
      @updateJobCanSchedule="handleUpdateJobCanSchedule"
    />
    <b-card
      no-body
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Customer
        </h5>
        <b-badge
          v-if="viewCustomerData !== null"
          variant="light-primary"
        >
          {{ viewCustomerData.company !== null && viewCustomerData.company ? 'Company' : "" }}
        </b-badge>
        <small
          v-if="viewCustomerData"
          class="text-muted w-100"
        >{{ viewCustomerData.email }}</small>
      </b-card-header>

      <b-card-body v-if="!viewCustomerData">
        Customer not added.
        <b-row class="px-1 pt-2 d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            @click="isEditCustomerSidebarActive = true"
          >
            <span class="text-nowrap">Add Customer</span>
          </b-button>
        </b-row>
      </b-card-body>
      <b-card-body v-else>
        <ul
          v-if="viewCustomerData"
          class="list-unstyled my-1"
        >
          <li>
            <span class="align-middle">{{ viewCustomerData.fullName }}</span>
          </li>
          <li class="my-25">
            <span class="align-middle">Phone: {{ viewCustomerData.phoneNumber }}</span>
          </li>
          <li class="my-25">
            <span class="align-middle">Postal Code: {{ viewCustomerData.postalCode ? viewCustomerData.postalCode : "N/A" }}</span>
          </li>
          <li class="my-25">
            <span class="align-middle">Street Address: {{ viewCustomerData.streetAddress ? viewCustomerData.streetAddress : "N/A" }}</span>
          </li>

          <span v-if="viewCustomerData.company === true">
            <li class="mt-1 text-muted">
              <span class="align-middle">Business Details</span>
            </li>
            <li class="my-25">
              <span class="align-middle">Business Name: {{ viewCustomerData.companyName ? viewCustomerData.companyName : "N/A" }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">VAT Number: {{ viewCustomerData.companyVatNumber ? viewCustomerData.companyVatNumber : "N/A" }}</span>
            </li>
          </span>

        </ul>
        <b-row
          align-h="end"
          class="mx-auto justify-content-between"
        >
          <b-button
            v-if="!!viewCustomerData.id"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :to="{name: 'customers-view', params: {id: viewCustomerData.id }}"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            <span>View</span>
          </b-button>
          <b-button
            v-if="isEditable"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="isEditCustomerSidebarActive = true"
          >
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-button>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import customerStoreModule from '@/views/customer/customerStoreModule'
import JobEditCustomer from '@/views/job/jobs-edit/JobEditCustomer.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BBadge,
    JobEditCustomer,
  },
  props: {
    customerData: {
      type: Object,
      required: false,
      default: null,
    },
    jobId: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      viewCustomerData: this.$props.customerData,
    }
  },
  methods: {
    jobViewCustomerUpdate(data) {
      this.viewCustomerData = data
    },
    handleUpdateJobCanSchedule(data) {
      this.$emit('updateJobCanSchedule', data)
    },
  },
  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'
    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const isEditCustomerSidebarActive = ref(false)
    return {
      isEditCustomerSidebarActive,
    }
  },
}
</script>

<style>

</style>
