var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"edit-job-sidebar","visible":_vm.isEditJobSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-edit-job-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Edit Job Info ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleUpdateJobInfo)}}},[_c('b-form-group',{attrs:{"label":"Job Template","label-for":"job-template-data"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.jobTemplateOptions,"reduce":function (val) { return val; },"clearable":false,"label":"alias","input-id":"job-template"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var alias = ref.alias;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(" "+_vm._s(alias)+" ")])]}}],null,true),model:{value:(_vm.newJobData.jobTemplate),callback:function ($$v) {_vm.$set(_vm.newJobData, "jobTemplate", $$v)},expression:"newJobData.jobTemplate"}})],1),_c('b-form-group',{attrs:{"label":"Job Alias","label-for":"alias"}},[_c('validation-provider',{attrs:{"name":"Job Alias","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postalCode","placeholder":"Enter Job Alias"},model:{value:(_vm.newJobData.alias),callback:function ($$v) {_vm.$set(_vm.newJobData, "alias", $$v)},expression:"newJobData.alias"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Scheduled Date","label-for":"scheduled"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.dateTimePickerConfig},model:{value:(_vm.newJobData.scheduled),callback:function ($$v) {_vm.$set(_vm.newJobData, "scheduled", $$v)},expression:"newJobData.scheduled"}})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Description","rows":"3","max-rows":"30"},model:{value:(_vm.newJobData.description),callback:function ($$v) {_vm.$set(_vm.newJobData, "description", $$v)},expression:"newJobData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!!_vm.newJobData)?_c('div',[_c('b-card',{staticClass:"border-primary",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"d-flex justify-content-between pt-75 pb-25 px-1"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.newJobData.alias)+" ")])]),_c('b-card-body',{staticClass:"px-1"},[_c('ul',{staticClass:"list-unstyled my-1"},[_c('li',[_c('span',{staticClass:"align-middle"},[_vm._v("Job Type: "+_vm._s(_vm.newJobData.jobTemplate ? _vm.newJobData.jobTemplate.alias : 'Not selected'))])]),_c('li',[_c('span',{staticClass:"align-middle"},[_vm._v("Description: "+_vm._s(_vm.newJobData.description ? _vm.newJobData.description : 'N/A'))])])])])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-50",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isLoading ? "Updating..." : "Update")+" ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }