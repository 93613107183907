import axios from '@axios'

export default {
  namespaced: true,
  state: {
    vehicleActive: false,
  },
  getters: {
    isVehicleActive: state => state.vehicleActive,
  },
  mutations: {
    SET_VEHICLE_ACTIVE(state, payload) {
      state.vehicleActive = payload
    },
  },
  actions: {
    fetchJobs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJob(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/jobs/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteJob(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/jobs/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJob(ctx, { jobId, jobData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/jobs/${jobId}`, jobData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addJob(ctx, { jobData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/jobs', jobData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJobStatus(ctx, { jobId, status }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/jobs/${jobId}/status`, { status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJobTemplates() {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobTemplates', { params: { showAll: true } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJobCustomer(ctx, { jobId, customerId }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/jobs/${jobId}/customer`, { customerId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addJobCheckpoint(ctx, { jobId, checkpointData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/jobs/${jobId}/checkpoints`, checkpointData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
