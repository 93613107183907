<template>
  <vehicle-check-timeline-data
    v-if="progressItem !== null && progressItem.vehicleCheck !== undefined && progressItem.status === 'status.completed'"
    :vehicle-check-data="progressItem.vehicleCheck"
  />
  <checkpoint-timeline-data
    v-else-if="progressItem !== null && progressItem.checkpoint !== undefined && progressItem.status === 'status.completed'"
    :checkpoint-data="progressItem"
  />
  <signature-timeline-data
    v-else-if="progressItem !== null && progressItem.image !== undefined && progressItem.status === 'status.completed'"
    :signature-data="progressItem"
  />
</template>

<script>
import CheckpointTimelineData from '@/views/job/jobs-view/CheckpointTimelineData.vue'
import VehicleCheckTimelineData from '@/views/job/jobs-view/VehicleCheckTimelineData.vue'
import SignatureTimelineData from '@/views/job/jobs-view/SignatureTimelineData.vue'

export default {
  components: {
    CheckpointTimelineData,
    VehicleCheckTimelineData,
    SignatureTimelineData,
  },
  props: {
    progressItem: {
      type: Object,
      required: true,
    },
  },
}
</script>
