<template>
  <div>
    <job-edit-info
      :is-edit-job-sidebar-active.sync="isEditJobSidebarActive"
      :job-data="viewJobData"
      @updateJobInfo="jobViewInfoUpdate"
    />
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :variant="`light-${resolveJobStatusVariant(viewJobData.status)}`"
              size="104px"
              rounded
            >
              <feather-icon
                :icon="resolveJobStatusIcon(viewJobData.status)"
                size="70"
              />
            </b-avatar>
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ viewJobData.alias }}
                </h4>
                <span class="card-text">Type: {{ viewJobData.jobTemplate.alias }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-dropdown
                  id="dropdown-1"
                  variant="outline-primary"
                >
                  <template #button-content>
                    <b-spinner
                      v-if="isLoading"
                      small
                      class="mr-50"
                    />
                    <span>Actions</span>
                  </template>
                  <b-dropdown-item
                    :disabled="viewJobData.status !== 'status.draft'"
                    @click="isEditJobSidebarActive = true"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="viewJobData.status === 'status.draft'"
                    :disabled="!viewJobData.canSchedule"
                    variant="outline-warning"
                    @click="handleScheduleJob"
                  >
                    <feather-icon
                      v-if="!isLoading"
                      icon="ClockIcon"
                      class="mr-50"
                    />
                    <span>Schedule</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="viewJobData.status === 'status.scheduled'"
                    :disabled="!viewJobData.canSchedule"
                    class="text-info"
                    @click="unscheduleJob"
                  >
                    <span class="text-primary">
                      <feather-icon
                        v-if="!isLoading"
                        icon="ClockIcon"
                        class="mr-50"
                      />

                      <span>Unschedule</span>
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="viewJobData.status !== 'status.draft'"
                    variant="text-danger"
                    @click="deleteJob(viewJobData.id)"
                  >
                    <span class="text-danger">
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="HashIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">ID</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ viewJobData.uniqueToken.id }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ resolveJobStatusText(viewJobData.status) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Scheduled for</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ viewJobData.scheduled ? toHumanDate(viewJobData.scheduled) : 'N/A' }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-card-text>

            <span class="font-weight-bold">Description</span>
            <div class="mt-2">
              {{ viewJobData.description }}
            </div>

            <b-row
              align-h="end"
              class="text-muted w-100 mt-2 mx-0"
            >
              <b-col
                class="text-right"
              >
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Added: {{ toHumanDate(viewJobData.createdAt) }}</span>
              </b-col>
            </b-row>
          </b-card-text>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="vehicle-inactive-confirmation-modal"
      centered
      modal-class="modal-warning"
      title="Inactive Vehicle Confirmation"
      ok-title="Proceed"
      ok-variant="warning"
      :ok-disabled="!inactiveVehicleCheckbox"
      @ok="scheduleJob"
    >
      <b-card-text>
        <span>
          The job you are trying to schedule is currently associated with an inactive vehicle.
        </span>
        <div class="mt-2">
          <b-form-checkbox
            id="inactve-vehicle-checkbox"
            v-model="inactiveVehicleCheckbox"
            name="inactive-vehicle-checkbox"
            :value="true"
            :unchecked-value="false"
          >
            I acknowledge that the job cannot be started on the scheduled date if the vehicle is still not active.
          </b-form-checkbox>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import toHumanDate from '@/libs/utils/general/dates'
import { ref, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import JobEditInfo from '@/views/job/jobs-edit/JobEditInfo.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useJobsList from '../jobs-list/useJobsList'

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    JobEditInfo,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormCheckbox,
  },
  props: {
    jobData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewJobData: this.$props.jobData,
      isLoading: false,
      inactiveVehicleCheckbox: false,
    }
  },
  methods: {
    jobViewInfoUpdate(data) {
      this.viewJobData = data
      this.$emit('updateJobStatus', data.status)
      this.$emit('updateJobTemplate', data.jobTemplate)
      this.$emit('updateJobCanSchedule', data.canSchedule)
    },
    handleScheduleJob() {
      if (!this.isVehicleActive) {
        return this.$bvModal.show('vehicle-inactive-confirmation-modal')
      }
      return this.scheduleJob()
    },
    scheduleJob() {
      this.isLoading = true
      this.$store.dispatch('app-job/updateJobStatus', {
        jobId: this.viewJobData.id,
        status: 'status.scheduled',
      })
        .then(response => {
          this.jobViewInfoUpdate(response.data)

          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully scheduled this job.',
            },
          })
        })
        .catch(err => {
          if (err.response.data.errors !== undefined) {
            Object.entries(err.response.data.errors).forEach(errors => {
              const field = errors[0]
              const messages = errors[1]
              const toUppercase = str => str.toUpperCase()
              messages.forEach(errorMessage => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                    icon: 'XIcon',
                    variant: 'warning',
                    text: errorMessage,
                  },
                })
              })
            })
          }

          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while scheduling the job.',
            },
          })
          this.isLoading = false
        })
    },
    unscheduleJob() {
      this.isLoading = true
      this.$store.dispatch('app-job/updateJobStatus', {
        jobId: this.viewJobData.id,
        status: 'status.draft',
      })
        .then(response => {
          this.jobViewInfoUpdate(response.data)
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully unscheduled this job.',
            },
          })
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while unscheduling the job.',
            },
          })
        })
    },
    deleteJob(id) {
      this.isLoading = true
      this.$store.dispatch('app-job/deleteJob', id)
        .then(() => {
          this.$router.replace({ name: 'jobs-list' })
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job deleted',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully deleted this job.',
            },
          })
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job deletion failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while deleting the job.',
            },
          })
        })
    },
  },
  setup() {
    const { resolveJobStatusIcon, resolveJobStatusVariant, resolveJobStatusText } = useJobsList()
    const isEditJobSidebarActive = ref(false)
    const isVehicleActive = computed(() => store.getters['app-job/isVehicleActive'])

    return {
      avatarText,
      resolveJobStatusIcon,
      resolveJobStatusVariant,
      resolveJobStatusText,
      toHumanDate,
      isEditJobSidebarActive,
      isVehicleActive,
    }
  },
}
</script>

<style>

</style>
