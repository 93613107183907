import { extend } from 'vee-validate'
import PhoneNumber from 'awesome-phonenumber'

const phoneNumber = extend('phoneNumber', {
  message() {
    return 'This field is not a valid phone number'
  },
  validate(value) {
    const phone = new PhoneNumber(value, 'GB')
    return phone.isValid()
  },
})

export default phoneNumber
