<template>
  <div>
    <job-edit-vehicle
      :is-edit-vehicle-sidebar-active.sync="isEditVehicleSidebarActive"
      :vehicle-data="viewVehicleData"
      :job-id="jobId"
      @updateVehicle="jobViewVehicleUpdate"
      @updateJobCanSchedule="handleUpdateJobCanSchedule"
    />

    <b-card
      no-body
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Vehicle
        </h5>
        <b-badge
          v-if="!!viewVehicleData"
          :variant="`light-${resolveVehicleActiveVariant(viewVehicleData.active)}`"
        >
          {{ viewVehicleData.active ? 'Active' : 'Inactive' }}
        </b-badge>
        <small
          v-if="viewVehicleData"
          class="text-muted w-100"
        >{{ viewVehicleData.make + ' ' + viewVehicleData.model }}</small>
      </b-card-header>

      <b-card-body v-if="!viewVehicleData">
        No vehicle added.
        <b-row class="px-1 pt-2 d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            @click="isEditVehicleSidebarActive = true"
          >
            <span class="text-nowrap">Add Vehicle</span>
          </b-button>
        </b-row>
      </b-card-body>
      <b-card-body v-else>
        <ul
          v-if="viewVehicleData"
          class="list-unstyled my-1"
        >
          <li>
            <span class="align-middle">Registration: {{ viewVehicleData.registration }}</span>
          </li>
          <li class="my-25">
            <span class="align-middle">Current mileage: {{ viewVehicleData.mileage }}</span>
          </li>
        </ul>
        <b-row
          align-h="end"
          class="mx-auto justify-content-between"
        >
          <b-button
            v-if="!!viewVehicleData.id"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :to="{name: 'vehicles-view', params: {id: viewVehicleData.id }}"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            <span>View</span>
          </b-button>
          <b-button
            v-if="isEditable"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="isEditVehicleSidebarActive = true"
          >
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-button>
        </b-row>
      </b-card-body>

    </b-card>
    </b-sidebar></div>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useVehicleList from '@/views/vehicle/useVehicleList'
import { ref, onUnmounted } from '@vue/composition-api'
import JobEditVehicle from '@/views/job/jobs-edit/JobEditVehicle.vue'
import store from '@/store'
import vehicleStoreModule from '@/views/vehicle/vehicleStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BRow,
    JobEditVehicle,
  },
  props: {
    vehicleData: {
      type: Object,
      required: false,
      default: null,
    },
    jobId: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      viewVehicleData: this.$props.vehicleData,
    }
  },
  methods: {
    jobViewVehicleUpdate(data) {
      this.viewVehicleData = data
    },
    handleUpdateJobCanSchedule(data) {
      this.$emit('updateJobCanSchedule', data)
    },
  },
  setup() {
    const VEHICLE_APP_STORE_MODULE_NAME = 'app-vehicle'
    // Register module
    if (!store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.registerModule(VEHICLE_APP_STORE_MODULE_NAME, vehicleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.unregisterModule(VEHICLE_APP_STORE_MODULE_NAME)
    })
    const { resolveVehicleActiveVariant } = useVehicleList()
    const isEditVehicleSidebarActive = ref(false)
    return {
      resolveVehicleActiveVariant,
      isEditVehicleSidebarActive,
    }
  },
}
</script>

<style>

</style>
