import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVehicles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/vehicles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVehicleChecks(ctx, data) {
      const {
        limit, page, sort, direction,
      } = data
      const queryParams = {
        limit, page, sort, direction,
      }
      const { vehicleId } = data
      return new Promise((resolve, reject) => {
        axios
          .get(`/vehicles/${vehicleId}/vehicleChecks`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVehicle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/vehicles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVehicle(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/vehicles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllVehicles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/vehicles', { params: { showAll: true } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJobVehicle(ctx, { jobId, vehicleId }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/jobs/${jobId}/vehicle`, { vehicleId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVehicle(ctx, { vehicleId, vehicleData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/vehicles/${vehicleId}`, vehicleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addVehicle(ctx, { vehicleData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/vehicles', vehicleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    toggleVehicleActive(ctx, { id, active }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/vehicles/${id}/active`, { active })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patchStatus(ctx, { id, status }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/vehicles/${id}/status`, { status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
