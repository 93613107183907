<template>
  <b-sidebar
    id="edit-vehicle-sidebar"
    :visible="isEditVehicleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-vehicle-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Vehicle
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(updateJobVehicle)"
        >

          <!-- User Role -->
          <b-form-group
            label="Registration"
            label-for="vehicle-data"
          >
            <v-select
              v-model="newVehicleData"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="vehicleOptions"
              :reduce="val => val"
              :clearable="false"
              label="registration"
              input-id="vehicle-data"
            >
              <template #option="{ registration, active }">
                <div class="d-flex justify-content-between align-items-center">
                  {{ registration }}
                  <b-badge
                    :variant="`light-${resolveVehicleActiveVariant(active)}`"
                  >
                    {{ active ? 'Active' : 'Inactive' }}
                  </b-badge>
                </div>
              </template>
            </v-select>
          </b-form-group>

          <div v-if="!!newVehicleData">
            <b-card
              no-body
              :class="`border-${resolveVehicleActiveVariant(newVehicleData.active)}`"
            >
              <b-card-header class="d-flex justify-content-between pt-75 pb-25 px-1">
                <h3 class="mb-0">
                  {{ newVehicleData.make + ' ' + newVehicleData.model }}
                </h3>
                <b-badge
                  :variant="`light-${resolveVehicleActiveVariant(newVehicleData.active)}`"
                >
                  {{ newVehicleData.active ? 'Active' : 'Inactive' }}
                </b-badge>
              </b-card-header>
              <b-card-body class="px-1">
                <ul
                  v-if="newVehicleData"
                  class="list-unstyled my-1"
                >
                  <li>
                    <span class="align-middle">Registration: {{ newVehicleData.registration }}</span>
                  </li>
                  <li class="my-25">
                    <span class="align-middle">Current mileage: {{ newVehicleData.mileage }}</span>
                  </li>
                </ul>
              </b-card-body>
            </b-card>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                class="mr-50"
              />
              {{ isLoading ? "Updating..." : "Update" }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="flat-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BBadge, BFormGroup, BCard, BCardHeader, BCardBody, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVehicleList from '@/views/vehicle/useVehicleList'

export default {
  components: {
    BSidebar,
    BForm,
    BCard,
    BCardBody,
    BCardHeader,
    BBadge,
    BSpinner,
    BFormGroup,
    BButton,
    vSelect,

    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditVehicleSidebarActive',
    event: 'update:is-edit-vehicle-sidebar-active',
  },
  props: {
    isEditVehicleSidebarActive: {
      type: Boolean,
      required: true,
    },
    vehicleData: {
      type: Object,
      required: false,
      default: null,
    },
    jobId: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      required,
      newVehicleData: this.vehicleData,
      isLoading: false,
    }
  },
  methods: {
    updateJobVehicle() {
      this.isLoading = true
      const updateData = {
        jobId: this.jobId,
        vehicleId: this.newVehicleData.id,
      }

      store.dispatch('app-vehicle/updateJobVehicle', updateData)
        .then(response => {
          store.commit('app-job/SET_VEHICLE_ACTIVE', response.data.vehicle.active)
          this.$emit('updateVehicle', this.newVehicleData)
          this.$emit('update:is-edit-vehicle-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully changed the vehicle for this job.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$emit('update:is-edit-vehicle-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating the job.',
            },
          })
          this.isLoading = false
        })
    },
  },
  setup(props, { emit }) {
    const vehicleOptions = ref([])

    store.dispatch('app-vehicle/fetchAllVehicles')
      .then(response => {
        vehicleOptions.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          vehicleOptions.value = undefined
        }
      })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const { resolveVehicleActiveVariant } = useVehicleList()

    return {
      vehicleOptions,
      refFormObserver,
      getValidationState,
      resolveVehicleActiveVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-vehicle-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
