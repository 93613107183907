<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="jobData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching job data
      </h4>
      <div class="alert-body">
        No job found with this job id. Check the
        <b-link
          class="alert-link"
          :to="{ name: 'jobs-list'}"
        >
          Job List
        </b-link>
        for other jobs.
      </div>
    </b-alert>

    <template v-if="jobData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <job-view-job-info-card
            :job-data="jobData"
            @updateJobStatus="handleUpdateJobStatus"
            @updateJobTemplate="handleUpdateJobTemplate"
            @updateJobCanSchedule="handleUpdateJobCanSchedule"
          />
          <b-row>
            <b-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
            >
              <job-view-job-pick-up-card
                :pick-up-data="pickUpData"
                :job-id="jobData.id"
                :is-editable="jobData.status === 'status.draft'"
                @updateJobCanSchedule="handleUpdateJobCanSchedule"
              />
            </b-col>
            <b-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
            >
              <job-view-job-drop-off-card
                :drop-off-data="dropOffData"
                :job-id="jobData.id"
                :is-editable="jobData.status === 'status.draft'"
                @updateJobCanSchedule="handleUpdateJobCanSchedule"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
            >
              <job-view-job-progress-card
                :key="jobData.jobTemplate.id"
                :job-data="jobData"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          xl="3"
          lg="4"
          md="5"
        >
          <job-view-job-vehicle-card
            :vehicle-data="vehicleData"
            :job-id="jobData.id"
            :is-editable="jobData.status === 'status.draft'"
            @updateVehicleData="handleVehicleDataUpdate"
            @updateJobCanSchedule="handleUpdateJobCanSchedule"
          />
          <job-view-job-driver-card
            :driver-data="driverData"
            :job-id="jobData.id"
            :is-editable="jobData.status === 'status.draft'"
            @updateJobCanSchedule="handleUpdateJobCanSchedule"
          />
          <job-view-job-customer-card
            :customer-data="customerData"
            :job-id="jobData.id"
            :is-editable="jobData.status === 'status.draft'"
            @updateJobCanSchedule="handleUpdateJobCanSchedule"
          />
        </b-col>

      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import jobStoreModule from '@/views/job/jobStoreModule'
import JobViewJobInfoCard from '@/views/job/jobs-view/JobViewJobInfoCard.vue'
import JobViewJobVehicleCard from '@/views/job/jobs-view/JobViewJobVehicleCard.vue'
import JobViewJobDriverCard from '@/views/job/jobs-view/JobViewJobDriverCard.vue'
import JobViewJobCustomerCard from '@/views/job/jobs-view/JobViewJobCustomerCard.vue'
import JobViewJobPickUpCard from '@/views/job/jobs-view/JobViewJobPickUpCard.vue'
import JobViewJobDropOffCard from '@/views/job/jobs-view/JobViewJobDropOffCard.vue'
import JobViewJobProgressCard from '@/views/job/jobs-view/JobViewJobProgressCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    JobViewJobInfoCard,
    JobViewJobVehicleCard,
    JobViewJobDriverCard,
    JobViewJobCustomerCard,
    JobViewJobPickUpCard,
    JobViewJobDropOffCard,
    JobViewJobProgressCard,
  },
  methods: {
    splitJobData(newJobData) {
      this.jobData.value = newJobData
      this.driverData.value = newJobData.driver
      this.vehicleData.value = newJobData.vehicle
      this.customerData.value = newJobData.customer
      if (this.jobData.checkpoints) {
        this.pickUpData.value = newJobData.checkpoints?.find(
          checkpoint => checkpoint.type === 'type.pick-up',
        )
        this.dropOffData.value = newJobData.checkpoints?.find(
          checkpoint => checkpoint.type === 'type.drop-off',
        )
      }
    },
    handleUpdateJobStatus(newJobStatus) {
      this.jobData.status = newJobStatus
    },
    handleUpdateJobTemplate(newJobTemplate) {
      this.jobData.jobTemplate = newJobTemplate
    },
    handleVehicleDataUpdate(data) {
      this.jobData = data
    },
    handleUpdateJobCanSchedule(data) {
      this.jobData.canSchedule = data
    },
  },
  setup() {
    const jobData = ref(null)
    const pickUpData = ref(null)
    const dropOffData = ref(null)
    const driverData = ref(null)
    const vehicleData = ref(null)
    const customerData = ref(null)

    const JOB_APP_STORE_MODULE_NAME = 'app-job'

    // Register module
    if (!store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.registerModule(JOB_APP_STORE_MODULE_NAME, jobStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.unregisterModule(JOB_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-job/fetchJob', { id: router.currentRoute.params.id })
      .then(response => {
        if (response.data.vehicle) {
          store.commit('app-job/SET_VEHICLE_ACTIVE', response.data.vehicle.active)
        }
        jobData.value = response.data
        driverData.value = response.data.driver
        vehicleData.value = response.data.vehicle
        customerData.value = response.data.customer
        if (response.data.checkpoints) {
          pickUpData.value = response.data.checkpoints?.find(
            checkpoint => checkpoint.type === 'type.pick-up',
          )
          dropOffData.value = response.data.checkpoints?.find(
            checkpoint => checkpoint.type === 'type.drop-off',
          )
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          jobData.value = undefined
          driverData.value = undefined
          vehicleData.value = undefined
          customerData.value = undefined
          pickUpData.value = undefined
          dropOffData.value = undefined
        }
      })
    return {
      jobData,
      vehicleData,
      driverData,
      customerData,
      pickUpData,
      dropOffData,
    }
  },
}
</script>

<style>

</style>
